// src/utils/analyticsUtils.ts
import { collection, query, getDocs, getDoc, doc, Firestore, orderBy, startAt, endAt } from 'firebase/firestore';

export async function fetchDailyStats(db: Firestore, shopId: string): Promise<Record<string, any>> {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 30);
    
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    
    //console.log("Date de début:", formattedStartDate);
    //console.log("Date de fin:", formattedEndDate);
    
    const dailyStatsRef = collection(db, 'analytics', shopId, 'dailyStats');
    
    // Utiliser orderBy sur l'ID du document au lieu d'un champ 'date'
    const q = query(
        dailyStatsRef,
        orderBy('__name__'), // '__name__' représente l'ID du document
        startAt(formattedStartDate),
        endAt(formattedEndDate)
    );
    
    //console.log("Requête:", q);
    
    const snapshot = await getDocs(q);
    //console.log("Nombre de documents récupérés:", snapshot.size);
    
    const dailyData: Record<string, any> = {};
    snapshot.forEach(doc => {
        //console.log("ID du document:", doc.id, "Données:", doc.data());
        dailyData[doc.id] = doc.data();
    });
    return dailyData;
}
  
  export async function fetchYearlyStats(db: Firestore, shopId: string): Promise<Record<string, any>> {
    const yearlyStatsRef = collection(db, 'analytics', shopId, 'yearlyStats');
    const snapshot = await getDocs(yearlyStatsRef);
    
    const yearlyData: Record<string, any> = {};
    snapshot.forEach(doc => {
        yearlyData[doc.id] = doc.data();
    });
    return yearlyData;
  }
  
  export async function fetchAllTimeStats(db: Firestore, shopId: string): Promise<Record<string, any>> {
    const allTimeStatsRef = doc(db, 'analytics', shopId, 'allTimeStats', 'stats');
    const allTimeDoc = await getDoc(allTimeStatsRef);
    
    if (allTimeDoc.exists()) {
        return { 'all-time': allTimeDoc.data() };
      } else {
        return {};
      }
  }