export class Geocoder {
  async geocode(address: string): Promise<{ lat: number; lng: number } | null> {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}&limit=1`);
      const data = await response.json();
      
      if (data && data.length > 0) {
        const result = data[0];
        return { lat: parseFloat(result.lat), lng: parseFloat(result.lon) };
      } else {
        console.warn(`Aucun résultat trouvé pour l'adresse: ${address}`);
        return null;
      }
    } catch (error) {
      console.error(`Erreur lors du géocodage de l'adresse: ${address}`, error);
      return null;
    }
  }
}