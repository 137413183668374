import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  ChevronRight,
  CreditCard,
  QrCode,
  Gift,
  Store,
  BarChart2,
  User,
  Earth
} from "lucide-react";

const Onboarding: React.FC = () => {
  const [step, setStep] = useState(0);
  const [userType, setUserType] = useState<"client" | "merchant" | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkOnboarding(user.uid);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const checkOnboarding = async (userId: string) => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      if (!userData.onboardingCompleted) {
        // Vérifier si shops existe, sinon l'initialiser
        if (!userData.shops) {
          await updateDoc(userDocRef, { shops: [] });
        }
        // Continuer avec l'onboarding
      } else {
        navigate("/");
      }
    }
  };

  const clientSteps = [
    {
      title: "Bienvenue dans votre portefeuille digital !",
      content:
        "FideliPass révolutionne vos cartes de fidélité. Fini les cartes papier, place à la simplicité du numérique !",
    },
    {
      title: "Un QR code, une multitude d'avantages",
      content:
        "Présentez votre QR code unique lors de vos achats. Accumulez des points et des récompenses en un clin d'œil, sans effort.",
    },
    {
      title: "Suivez vos récompenses en temps réel",
      content:
        "Visualisez instantanément vos points et récompenses pour chaque commerce.",
    },
    {
      title: "Explorez un monde de fidélité",
      content:
        "Découvrez notre réseau grandissant de commerces partenaires. Nouvelles enseignes, nouvelles récompenses : l'aventure FideliPass commence !",
    },
  ];

  const merchantSteps = [
    {
      title: "Votre programme de fidélité 2.0",
      content:
        "Bienvenue sur FideliPass ! Prêt à transformer votre relation client ?",
    },
    {
      title: "Créez votre profil commerçant",
      content:
        "Renseignez les informations de votre commerce et personnalisez votre programme de fidélité.",
    },
    {
      title: "Scannez les QR codes",
      content:
        "Utilisez l'application pour scanner les QR codes de vos clients et attribuer des points.",
    },
    {
      title: "Vos données, votre succès",
      content:
        "Accédez à des statistiques détaillées sur votre programme de fidélité et vos clients.",
    },
    {
      title: "Brillez dans notre écosystème digital",
      content:
        "Gagnez en visibilité ! Votre commerce s'affiche sur notre carte interactive et se démarque dans notre répertoire de partenaires. Grâce à notre fonction de recherche intuitive, les clients vous trouvent facilement. Attirez une nouvelle clientèle déjà conquise par FideliPass et devenez leur prochaine destination favorite !",
    },
  ];

  const steps = userType === "client" ? clientSteps : merchantSteps;

  const handleFinishOnboarding = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        await updateDoc(doc(db, "users", user.uid), {
          onboardingCompleted: true,
        });
        if (userType === "merchant") {
          navigate("/payment");
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'onboarding:", error);
        // Gérer l'erreur (par exemple, afficher un message à l'utilisateur)
      }
    } else {
      console.error("Aucun utilisateur connecté");
      navigate("/login");
    }
  };

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      handleFinishOnboarding();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-background p-4 overflow-hidden">
      <div className="w-full max-w-md">
        {userType === null ? (
          <Card className="w-full max-w-md shadow-sm">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-center">
                Bienvenue sur FideliPass
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <p className="text-center text-muted-foreground mb-4">
                Êtes-vous un client ou un commerçant ?
              </p>
              <div className="space-y-4">
                <Button
                  onClick={() => setUserType("client")}
                  className="w-full h-12 text-lg font-semibold flex items-center justify-center"
                >
                  <User className="mr-2 h-5 w-5" /> Client
                </Button>
                <Button
                  onClick={() => setUserType("merchant")}
                  className="w-full h-12 text-lg font-semibold flex items-center justify-center"
                >
                  <Store className="mr-2 h-5 w-5" /> Commerçant
                </Button>
              </div>
            </CardContent>
          </Card>
        ) : (
          <div className="flex flex-col h-full">
            <Card className="w-full shadow-sm flex-grow flex flex-col">
              <CardHeader className="text-center">
                <CardTitle className="text-2xl font-bold">
                  {steps[step].title}
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-6 flex-grow flex flex-col justify-between">
                <div>
                  <div className="flex justify-center mb-6">
                    {userType === "client"
                      ? [
                          <CreditCard size={48} />,
                          <QrCode size={48} />,
                          <Gift size={48} />,
                          <Earth size={48} />,
                        ][step]
                      : [
                          <Store size={48} />,
                          <CreditCard size={48} />,
                          <QrCode size={48} />,
                          <BarChart2 size={48} />,
                          <Earth size={48} />,
                        ][step]}
                  </div>
                  <p className="text-center text-muted-foreground">
                    {steps[step].content}
                  </p>
                </div>
                <div>
                  <div className="flex justify-between items-center mb-4">
                    {steps.map((_, index) => (
                      <div
                        key={index}
                        className={`h-1 flex-1 ${
                          index <= step ? "bg-primary" : "bg-muted"
                        } ${index !== steps.length - 1 ? "mr-1" : ""}`}
                      />
                    ))}
                  </div>
                  <Button
                    onClick={handleNext}
                    className="w-full h-12 text-lg font-semibold"
                  >
                    {step < steps.length - 1 ? "Suivant" : "Commencer"}
                  </Button>
                </div>
              </CardContent>
            </Card>
            <div 
              className="flex items-center justify-center text-sm text-gray-500 cursor-pointer hover:text-gray-700 transition-colors duration-200 mt-4"
              onClick={handleFinishOnboarding}
            >
              Passer <ChevronRight className="ml-1 h-4 w-4" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
