import React, { useState, useEffect, useCallback } from "react";
import ShopList from "../components/ShopList";
import { Button } from "../@/components/ui/button";
import {
  RefreshCw,
  Phone,
  Globe,
  Instagram,
  Facebook,
  Map,
} from "lucide-react";
import { Shop } from "../App";
import { auth, db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../@/components/ui/dialog";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as FideliPassLogo } from "../assets/FideliPass nom.svg";
import { Browser } from "@capacitor/browser";

const WalletPage: React.FC = () => {
  const [showCompleted, setShowCompleted] = useState(false);
  const [localShops, setLocalShops] = useState<Shop[]>([]);
  const [message, setMessage] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [startY, setStartY] = useState(0);
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isNative = Capacitor.isNativePlatform();
  const [lastRefreshTime, setLastRefreshTime] = useState<number>(0);
  const REFRESH_INTERVAL = 5000;
  const [refreshCooldown, setRefreshCooldown] = useState<number>(0);
  const RELOAD_ATTEMPT_KEY = "lastReloadAttempt";
  const MAX_RELOADS_PER_INTERVAL = 3;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (refreshCooldown > 0) {
      intervalId = setInterval(() => {
        setRefreshCooldown((prev) => Math.max(0, prev - 1));
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [refreshCooldown]);

  useEffect(() => {
    const lastRefreshTimeFromStorage = localStorage.getItem("lastRefreshTime");
    const parsedLastRefreshTime = lastRefreshTimeFromStorage
      ? parseInt(lastRefreshTimeFromStorage, 10)
      : 0;
    setLastRefreshTime(parsedLastRefreshTime);

    const remainingTime = Math.ceil(
      (REFRESH_INTERVAL - (Date.now() - parsedLastRefreshTime)) / 1000
    );
    setRefreshCooldown(Math.max(0, remainingTime));
  }, []);

  useEffect(() => {
    const completedShops = localShops.filter(
      (shop) => shop.progression === shop.bubbleCount
    );

    if (showCompleted) {
      if (completedShops.length === 0) {
        setMessage("Aucune carte complétée. Découvrez plus de magasins !");
      } else {
        setMessage("");
      }
    } else {
      if (localShops.length === 0) {
        setMessage(
          'Aucune carte de fidélité ajoutée pour le moment. Cliquez sur "Découvrir" pour explorer et ajouter des commerces.'
        );
      } else {
        setMessage("");
      }
    }
  }, [showCompleted, localShops]);

  const handleShopClick = (shop: Shop) => {
    setSelectedShop(shop);
    setIsDialogOpen(true);
  };

  const filteredShops = showCompleted
    ? localShops.filter((shop) => shop.progression === shop.bubbleCount)
    : localShops;

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const currentY = e.touches[0].clientY;
    const diff = currentY - startY;
    if (diff > 50 && scrollPosition === 0 && !isRefreshing) {
      setIsRefreshing(true);
    }
  };

  const handleTouchEnd = async () => {
    if (isRefreshing) {
      const currentTime = Date.now();
      const lastRefreshTimeFromStorage =
        localStorage.getItem("lastRefreshTime");
      const parsedLastRefreshTime = lastRefreshTimeFromStorage
        ? parseInt(lastRefreshTimeFromStorage, 10)
        : 0;

      if (currentTime - parsedLastRefreshTime < REFRESH_INTERVAL) {
        /*console.log(
          "Rafraîchissement limité. Veuillez attendre avant de réessayer."
        );*/
        setIsRefreshing(false);
        return;
      }
      await refreshShops();
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollPosition(e.currentTarget.scrollTop);
  };

  const refreshShops = useCallback(async () => {
    const currentTime = Date.now();
    const lastRefreshTimeFromStorage = localStorage.getItem("lastRefreshTime");
    const parsedLastRefreshTime = lastRefreshTimeFromStorage
      ? parseInt(lastRefreshTimeFromStorage, 10)
      : 0;

    if (currentTime - parsedLastRefreshTime < REFRESH_INTERVAL) {
      /*
      console.log(
        "Rafraîchissement limité. Veuillez attendre avant de réessayer."
      );*/
      return;
    }

    setIsRefreshing(true);
    const user = auth.currentUser;
    if (user) {
      try {
        const walletRef = collection(db, "wallets", user.uid, "wallet");
        const querySnapshot = await getDocs(walletRef);
        const updatedShops: Shop[] = [];
        querySnapshot.forEach((doc) => {
          updatedShops.push({ id: doc.id, ...doc.data() } as Shop);
        });
        setLocalShops(updatedShops);

        // Mise à jour du localStorage et de l'état
        localStorage.setItem("lastRefreshTime", currentTime.toString());
        localStorage.setItem("cachedShops", JSON.stringify(updatedShops));
        setLastRefreshTime(currentTime);

        // Mise à jour du cooldown
        setRefreshCooldown(REFRESH_INTERVAL / 1000);
      } catch (error) {
        console.error("Erreur lors du rafraîchissement des magasins:", error);
      } finally {
        setIsRefreshing(false);
      }
    }
  }, []);

  useEffect(() => {
    const now = Date.now();
    const lastReloadAttempt = localStorage.getItem(RELOAD_ATTEMPT_KEY);
    const reloadAttempts = localStorage.getItem("reloadAttempts");

    if (lastReloadAttempt && reloadAttempts) {
      const timeSinceLastAttempt = now - parseInt(lastReloadAttempt, 10);
      const attempts = parseInt(reloadAttempts, 10);

      if (
        timeSinceLastAttempt < REFRESH_INTERVAL &&
        attempts >= MAX_RELOADS_PER_INTERVAL
      ) {
        /*
        console.log(
          "Trop de tentatives de rechargement. Utilisation des données en cache."
        );*/
        const cachedShops = localStorage.getItem("cachedShops");
        if (cachedShops) {
          setLocalShops(JSON.parse(cachedShops));
        }
        return;
      }

      if (timeSinceLastAttempt < REFRESH_INTERVAL) {
        localStorage.setItem("reloadAttempts", (attempts + 1).toString());
      } else {
        localStorage.setItem("reloadAttempts", "1");
      }
    } else {
      localStorage.setItem("reloadAttempts", "1");
    }

    localStorage.setItem(RELOAD_ATTEMPT_KEY, now.toString());

    const initialLoad = async () => {
      const lastRefreshTimeFromStorage =
        localStorage.getItem("lastRefreshTime");
      const parsedLastRefreshTime = lastRefreshTimeFromStorage
        ? parseInt(lastRefreshTimeFromStorage, 10)
        : 0;

      if (now - parsedLastRefreshTime < REFRESH_INTERVAL) {
        //console.log("Chargement limité. Utilisation des données en cache.");
        const cachedShops = localStorage.getItem("cachedShops");
        if (cachedShops) {
          setLocalShops(JSON.parse(cachedShops));
        }
      } else {
        await refreshShops();
      }
    };

    initialLoad();
  }, [refreshShops]);

  const toggleShowCompleted = useCallback(() => {
    setShowCompleted((prev) => !prev);
  }, []);

  const openLink = async (url: string | undefined) => {
    if (!url) return;

    if (Capacitor.isNativePlatform()) {
      // Sur mobile, utilisez le navigateur intégré de Capacitor
      await Browser.open({ url });
    } else {
      // Sur ordinateur, ouvrez dans une nouvelle fenêtre
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const openInMaps = (shop: Shop | null) => {
    if (!shop) return;

    const address = `${shop.address}, ${shop.postalCode} ${shop.city}, ${shop.country}`;
    const encodedAddress = encodeURIComponent(address);

    // Vérifier si l'utilisateur est sur un appareil iOS ou macOS
    const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(
      navigator.userAgent
    );

    if (isAppleDevice) {
      // Ouvrir dans Apple Maps
      window.location.href = `maps:0,0?q=${encodedAddress}`;
    } else {
      // Ouvrir dans Google Maps
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
        "_blank"
      );
    }
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <FideliPassLogo className="h-5 w-auto" />
          <div className="w-12 h-12"></div>
        </div>
        <div className="mt-4 h-12">
          <Button
            onClick={toggleShowCompleted}
            className={`w-full h-12 transition-colors duration-200 ${
              showCompleted
                ? "bg-black text-white"
                : isNative
                ? "bg-transparent text-black border border-black"
                : "bg-white text-black border border-black hover:bg-gray-100"
            }`}
            style={
              isNative && !showCompleted ? { backgroundColor: "white" } : {}
            }
          >
            {showCompleted
              ? "Afficher toutes les cartes"
              : "Afficher cartes complétées"}
          </Button>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
          <div
            className="overflow-y-auto pb-16"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onScroll={handleScroll}
          >
            {isRefreshing && (
              <div className="flex flex-col justify-center items-center py-4">
                <RefreshCw className="animate-spin h-6 w-6 mb-2" />
                {refreshCooldown > 0 && (
                  <p className="text-sm text-gray-500">
                    Prochain rafraîchissement dans {refreshCooldown}s
                  </p>
                )}
              </div>
            )}
            {filteredShops.length === 0 ? (
              <p className="text-center text-gray-500 mt-4">{message}</p>
            ) : (
              <ShopList shops={filteredShops} onShopClick={handleShopClick} />
            )}
          </div>
        </div>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-left">
              {selectedShop?.name || "Détails du magasin"}
            </DialogTitle>
            <DialogDescription className="text-left text-sm text-muted-foreground">
              {selectedShop?.address || "Adresse non spécifiée"},{" "}
              {selectedShop?.postalCode || ""} {selectedShop?.city || ""}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4 text-left">
            {selectedShop?.showDescription && selectedShop?.description && (
              <p className="text-left">{selectedShop.description}</p>
            )}
            {selectedShop?.offre && (
              <p className="mt-2 font-semibold text-left">
                Offre à la complétion : {selectedShop.offre}
              </p>
            )}
          </div>
          <div className="flex justify-start space-x-4 mt-4">
            <button
              onClick={() => openInMaps(selectedShop)}
              className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
            >
              <Map className="w-6 h-6" />
            </button>
            {selectedShop?.showPhone && selectedShop?.phone && (
              <a
                href={`tel:${selectedShop.phone}`}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Phone className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showWebsite && selectedShop?.website && (
              <button
                onClick={() => openLink(selectedShop.website)}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Globe className="w-6 h-6" />
              </button>
            )}
            {selectedShop?.showInstagram && selectedShop?.instagram && (
              <a
                href={selectedShop.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Instagram className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showFacebook && selectedShop?.facebook && (
              <a
                href={selectedShop.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Facebook className="w-6 h-6" />
              </a>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WalletPage;
