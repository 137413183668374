import { useEffect, useState } from 'react';

function useMapSize() {
  const [mapSize, setMapSize] = useState({ width: '100%', height: '100%' });

  useEffect(() => {
    function updateSize() {
      const headerHeight = 64; // Hauteur de votre en-tête
      const windowHeight = window.innerHeight;
      setMapSize({
        width: '100%',
        height: `${windowHeight - headerHeight}px`
      });
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return mapSize;
}

export default useMapSize;