import Fuse from 'fuse.js';

export const countryMap: { [key: string]: string } = {
  "Suisse": "CH",
  "France": "FR",
  "Germany": "DE",
  "Spain": "ES",
  "Italy": "IT",
  "Portugal": "PT",
  "Greece": "GR",
  "Netherlands": "NL",
  "Belgique": "BE",
  "Luxembourg": "LU",
  "Austria": "AT",
  "Poland": "PL",
  "Czech Republic": "CZ",
  "Hungary": "HU",
  "Finland": "FI",
  "Sweden": "SE",
  "Denmark": "DK",
  "Norway": "NO",
  "Iceland": "IS",
  "Russia": "RU",
  "Ukraine": "UA",
  "Turkey": "TR",
  "China": "CN",
  "Japan": "JP",
  "South Korea": "KR",
  "India": "IN",
  "Brazil": "BR",
  "Mexico": "MX",
  "Argentina": "AR",
  "Canada": "CA",
  "United States": "US",
  "Australia": "AU",
  "New Zealand": "NZ",
  "South Africa": "ZA",
  "Saudi Arabia": "SA",
  "United Arab Emirates": "AE",
  "Israel": "IL",
  "United Kingdom": "GB",
  "Ireland": "IE",
  "Singapore": "SG",
  "Malaysia": "MY",
  "Philippines": "PH",
  "Indonesia": "ID",
  "Thailand": "TH",
  "Vietnam": "VN",
  "Hong Kong": "HK",
  "Taiwan": "TW",
  "Chile": "CL",
  "Peru": "PE",
  "Colombia": "CO",
  "Venezuela": "VE",
  "Panama": "PA",
  "Costa Rica": "CR",
  "Uruguay": "UY",
  "Paraguay": "PY",
  "Ecuador": "EC",
  "Bolivia": "BO",
  "Dominican Republic": "DO",
  "El Salvador": "SV",
  "Honduras": "HN",
  "Guatemala": "GT",
  "Jamaica": "JM",
  "Trinidad and Tobago": "TT",
  "Egypt": "EG",
  "Morocco": "MA",
  "Tunisia": "TN",
  "Kenya": "KE",
  "Nigeria": "NG",
  "Ghana": "GH",
  "Tanzania": "TZ",
  "Uganda": "UG",
  "Kazakhstan": "KZ",
  "Uzbekistan": "UZ",
  "Kuwait": "KW",
  "Qatar": "QA",
  "Bahrain": "BH",
  "Oman": "OM",
  "Jordan": "JO",
  "Lebanon": "LB",
  "Pakistan": "PK",
  "Bangladesh": "BD",
  "Sri Lanka": "LK",
  "Nepal": "NP",
  "Maldives": "MV",
  "Fiji": "FJ",
  "Papua New Guinea": "PG",
};

const fuse = new Fuse(Object.keys(countryMap), {
  includeScore: true,
  threshold: 0.3, // Ajustez ce seuil selon vos besoins
});

export function getCountryCode(countryName: string): string {
  const normalizedName = countryName.trim();
  
  // Vérification directe
  if (countryMap[normalizedName]) {
    return countryMap[normalizedName];
  }

  // Recherche floue
  const results = fuse.search(normalizedName);
  
  if (results.length > 0) {
    const bestMatch = results[0].item;
    //console.log(`Meilleure correspondance trouvée pour "${countryName}": "${bestMatch}"`);
    return countryMap[bestMatch];
  }

  console.warn(`Code pays non trouvé pour: ${countryName}`);
  return "UNKNOWN";
}