import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { Plus } from "lucide-react";
import { auth, db } from "../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  increment,
  writeBatch,
  FieldValue,
  deleteField,
  setDoc,
} from "firebase/firestore";
import { Capacitor } from "@capacitor/core";
import { Camera } from "@capacitor/camera";
import useRevenueCatEntitlement from "../hooks/useRevenueCatEntitlement";
import jsQR from "jsqr";
import {
  CameraPreview,
  CameraSampleOptions,
} from "@capacitor-community/camera-preview";
import "./CameraPage.css";
import { ReactComponent as FideliPassLogo } from "../assets/FideliPass nom.svg";
import { useLocation } from "react-router-dom";
import { Dialog as CapacitorDialog } from "@capacitor/dialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../@/components/ui/dialog";

interface ShopData {
  progression: number;
  bubbleCount: number;
  newBubbleCount?: number;
  redemptionCount: number;
  added: boolean;
  address: string;
  canton: string;
  city: string;
  country: string;
  description: string;
  name: string;
  facebook: string;
  id: string;
  instagram: string;
  offre: string;
  phone: string;
  postalCode: string;
  showDescription: boolean;
  showFacebook: boolean;
  showInstagram: boolean;
  showPhone: boolean;
  showWebsite: boolean;
  userId: string;
  website: string;
  addedDate?: string;
}

const CameraPage: React.FC = () => {
  const navigate = useNavigate();
  const [scannedUserId, setScannedUserId] = useState<string | null>(null);
  const [merchantShops, setMerchantShops] = useState<any[]>([]);
  const [selectedShop, setSelectedShop] = useState<string>("");
  const selectedShopRef = useRef<string>("");
  const [showAddButton, setShowAddButton] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isNative = Capacitor.isNativePlatform();
  const {
    hasActiveEntitlement,
    entitlements,
    subscriptionStatus,
    isLoading: isRevenueCatLoading,
  } = useRevenueCatEntitlement();
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const scanIntervalRef = useRef<number | null>(null);
  const location = useLocation();
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [currentUpgradeLevel, setCurrentUpgradeLevel] = useState("");

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, [location]);

  const checkAndRequestCameraPermission = async () => {
    if (Capacitor.isNativePlatform()) {
      const permissionStatus = await Camera.checkPermissions();
      if (permissionStatus.camera !== "granted") {
        const request = await Camera.requestPermissions();
        return request.camera === "granted";
      }
      return true;
    }
    return true; // Sur le web, supposons que la permission est accordée
  };

  const startWebScanner = useCallback(async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
      }
    } catch (err) {
      console.error("Erreur lors de l'accès à la caméra:", err);
    }
  }, []);

  const stopCamera = async () => {
    if (isNative) {
      try {
        await CameraPreview.stop();
        //console.log("Caméra arrêtée avec succès");
      } catch (error) {
        console.error("Erreur lors de l'arrêt de la caméra:", error);
      }
    } else {
      // Arrêter le flux vidéo pour la version web
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
    setIsScanning(false);
  };

  const scanQRCode = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas && video.videoWidth && video.videoHeight) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        //console.log("QR Code détecté", code.data);
        verifyAndSetScannedUser(code.data);
      }
    }
  }, []);

  useEffect(() => {
    const fetchMerchantShops = async () => {
      const user = auth.currentUser;
      if (user) {
        const q = query(
          collection(db, "merchants"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);
        const shops = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          shopId: doc.data().shopId,
        }));
        setMerchantShops(shops);

        if (shops.length === 1) {
          const currentShop = shops[0].shopId;
          setSelectedShop(currentShop);
          selectedShopRef.current = currentShop;
          localStorage.setItem("lastSelectedShop", currentShop);
        } else {
          const lastSelectedShop = localStorage.getItem("lastSelectedShop");
          if (
            lastSelectedShop &&
            shops.some((shop) => shop.id === lastSelectedShop)
          ) {
            setSelectedShop(lastSelectedShop);
            selectedShopRef.current = lastSelectedShop;
          } else {
            setSelectedShop(shops[0].id);
            selectedShopRef.current = shops[0].id;
            localStorage.setItem("lastSelectedShop", shops[0].id);
          }
        }
      }
    };

    fetchMerchantShops();

    if (subscriptionStatus && subscriptionStatus.isActive && !isNative) {
      startWebScanner();
    }

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
      }
      if (scanIntervalRef.current) {
        clearInterval(scanIntervalRef.current);
      }
    };
  }, [subscriptionStatus, isNative, startWebScanner]);

  useEffect(() => {
    if (isVideoReady && !isNative) {
      scanIntervalRef.current = window.setInterval(scanQRCode, 500);
    }
    return () => {
      if (scanIntervalRef.current) {
        clearInterval(scanIntervalRef.current);
      }
    };
  }, [isVideoReady, isNative, scanQRCode]);

  const handleVideoMetadataLoaded = () => {
    setIsVideoReady(true);
  };

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
      scanQRCode();
    }
  }, [stream]);

  useEffect(() => {
    // Cette fonction de nettoyage sera appelée lorsque le composant sera démonté
    return () => {
      stopCamera();
      if (scanIntervalRef.current) {
        clearInterval(scanIntervalRef.current);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const startScanner = async () => {
    const hasCameraPermission = await checkAndRequestCameraPermission();
    if (!hasCameraPermission) {
      alert("L'application n'a pas la permission d'accéder à la caméra.");
      return;
    }

    setIsScanning(true);
    try {
      await CameraPreview.start({
        parent: "camera-preview",
        className: "camera-preview-container",
        toBack: false,
        position: "rear",
        x: 0,
        y: 0,
        width: window.screen.width,
        height: window.screen.height,
        enableZoom: false,
        disableExifHeaderStripping: true,
        disableAudio: true,
      });
      //console.log("Caméra démarrée avec succès");

      const cameraSampleOptions: CameraSampleOptions = {
        quality: 85,
      };

      const scanQRInterval = setInterval(async () => {
        try {
          const result = await CameraPreview.captureSample(cameraSampleOptions);
          if (result.value) {
            //console.log("Image capturée");
            const scannedData = await processImageData(result.value);
            if (scannedData) {
              //console.log("QR code détecté:", scannedData);
              clearInterval(scanQRInterval);
              await CameraPreview.stop();
              verifyAndSetScannedUser(scannedData);
            } else {
              //console.log("Aucun QR code détecté dans cette image");
            }
          } else {
            //console.log("Pas de données d'image reçues");
          }
        } catch (captureError) {
          console.error("Erreur lors de la capture :", captureError);
        }
      }, 1000);

      setTimeout(() => {
        clearInterval(scanQRInterval);
        CameraPreview.stop()
          .then(() => {
            setIsScanning(false);
            //console.log("Scan terminé sans détection de QR code");
            alert("Aucun QR code détecté. Veuillez réessayer.");
          })
          .catch((error) => {
            console.error("Erreur lors de l'arrêt de la caméra:", error);
          });
      }, 20000);
    } catch (error) {
      console.error("Erreur lors de l'initialisation de la caméra:", error);
      alert(
        "Une erreur s'est produite lors de l'initialisation de la caméra. Veuillez vérifier les permissions de l'application."
      );
      setIsScanning(false);
    }
  };

  const processImageData = (base64Data: string): Promise<string | null> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, image.width, image.height);
          const imageData = context.getImageData(
            0,
            0,
            image.width,
            image.height
          );
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          resolve(code ? code.data : null);
        } else {
          console.error("Impossible de créer le contexte 2D");
          resolve(null);
        }
      };
      image.onerror = () => {
        console.error("Erreur lors du chargement de l'image");
        resolve(null);
      };
      image.src = `data:image/jpeg;base64,${base64Data}`;
    });
  };

  const verifyAndSetScannedUser = async (scannedData: string) => {
    if (!selectedShopRef.current) {
      alert(
        "Erreur: Aucun commerce sélectionné. Veuillez sélectionner un commerce."
      );
      return;
    }

    try {
      const userDocRef = doc(db, "users", scannedData);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        if (scannedData !== scannedUserId) {
          setScannedUserId(scannedData);
          setShowAddButton(true);
          setIsOverlayVisible(true);

          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            setShowAddButton(false);
            setIsOverlayVisible(false);
            setScannedUserId(null);
            if (isNative) {
              startScanner();
            }
          }, 5000);
        }
      } else {
        setShowAddButton(false);
        setIsOverlayVisible(false);
        setScannedUserId(null);
        if (isNative) {
          startScanner();
        }
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de l'utilisateur:", error);
      setShowAddButton(false);
      setIsOverlayVisible(false);
      setScannedUserId(null);
      if (isNative) {
        startScanner();
      }
    }
  };

  const confirmNewAccountAddition = async () => {
    if (isNative) {
      const { value } = await CapacitorDialog.confirm({
        title: "Ajouter un nouveau compte",
        message:
          "Vous avez dépassé la limite mensuelle. Voulez-vous vraiment ajouter ce nouveau compte ?",
        okButtonTitle: "Oui",
        cancelButtonTitle: "Non",
      });
      return value;
    } else {
      return window.confirm(
        "Vous avez dépassé la limite mensuelle. Voulez-vous vraiment ajouter ce nouveau compte ?"
      );
    }
  };

  const handleAddPoint = async () => {
    if (scannedUserId && selectedShopRef.current) {
      const shopDocRef = doc(
        db,
        "wallets",
        scannedUserId,
        "wallet",
        selectedShopRef.current
      );

      let pointsAdded = 1;
      let isRedemption = false;
      let isNewSignUp = false;

      const currentShopId = selectedShopRef.current;

      // Vérifier le niveau d'abonnement et le nombre total de clients
      const { subscriptionLevel, monthlyNewSignUps } =
        await checkSubscriptionAndClients(currentShopId);
      const limit = getSubscriptionLimit(subscriptionLevel);

      const shopDocSnap = await getDoc(shopDocRef);
      const isExistingAccount = shopDocSnap.exists();

      if (monthlyNewSignUps >= limit * 1.1) {
        if (isExistingAccount) {
          // Autoriser l'ajout de points pour les comptes existants
          // eslint-disable-next-line no-console
          console.log(
            "Compte existant : ajout de points autorisé malgré le dépassement de la limite"
          );
        } else {
          // Bloquer l'ajout pour les nouveaux comptes
          await showUpgradeRequired(subscriptionLevel);
          return;
        }
      } else if (monthlyNewSignUps >= limit) {
        await showUpgradeWarning(subscriptionLevel);
        if (!isExistingAccount) {
          // Permettre l'ajout mais avertir pour les nouveaux comptes
          const shouldProceed = await confirmNewAccountAddition();
          if (!shouldProceed) {
            return;
          }
        }
      }

      if (isExistingAccount) {
        const shopData = shopDocSnap.data() as ShopData;
        const newProgression = (shopData.progression || 0) + 1;

        if (newProgression > shopData.bubbleCount) {
          await updateDoc(shopDocRef, {
            progression: 0,
            redemptionCount: increment(1),
            bubbleCount: shopData.newBubbleCount || shopData.bubbleCount,
          });

          if (shopData.newBubbleCount) {
            await updateDoc(shopDocRef, { newBubbleCount: deleteField() });
          }

          isRedemption = true;
          alert(
            "Félicitations ! La carte a été complétée et une nouvelle carte a été démarrée."
          );
        } else {
          await updateDoc(shopDocRef, { progression: newProgression });
          alert(
            `Point ajouté avec succès ! (${newProgression}/${shopData.bubbleCount})`
          );
        }
      } else {
        const merchantShop = merchantShops.find(
          (shop) => shop.shopId === selectedShopRef.current
        );
        if (merchantShop) {
          const currentDate = new Date().toISOString();
          await setDoc(shopDocRef, {
            ...merchantShop,
            added: true,
            progression: 1,
            redemptionCount: 0,
            addedDate: currentDate,
          });
          isNewSignUp = true;
          alert("Nouvelle carte ajoutée avec succès !");
        }
      }

      await handleSuccessfulScan(
        scannedUserId,
        currentShopId,
        pointsAdded,
        isRedemption,
        isNewSignUp
      );

      setShowAddButton(false);
      setIsOverlayVisible(false);
      setScannedUserId(null);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      setIsScanning(false);
    }
  };

  const handleSuccessfulScan = async (
    userId: string,
    merchantId: string,
    pointsAdded: number,
    isRedemption: boolean,
    isNewSignUp: boolean
  ) => {
    const now = new Date();
    const dateString = now.toISOString().split("T")[0];
    const yearMonth = `${now.getFullYear()}-${String(
      now.getMonth() + 1
    ).padStart(2, "0")}`;
    const year = now.getFullYear().toString();

    const analyticsRef = doc(db, "analytics", merchantId);
    const dailyStatsRef = doc(analyticsRef, "dailyStats", dateString);
    const monthlyStatsRef = doc(analyticsRef, "monthlyStats", yearMonth);
    const yearlyStatsRef = doc(analyticsRef, "yearlyStats", year);
    const allTimeStatsRef = doc(analyticsRef, "allTimeStats", "stats");

    const batch = writeBatch(db);

    // Vérifier si l'utilisateur a déjà été compté
    const userCountsRef = doc(
      db,
      "analytics",
      merchantId,
      "userCounts",
      userId
    );
    const userCountsSnap = await getDoc(userCountsRef);
    const userCounts = userCountsSnap.exists() ? userCountsSnap.data() : {};

    const updateData: { [key: string]: FieldValue } = {
      pointsAdded: increment(pointsAdded),
      redemptions: increment(isRedemption ? 1 : 0),
      newSignUps: increment(isNewSignUp ? 1 : 0),
    };

    // Mise à jour du comptage quotidien
    if (
      !userCounts.lastDailyCount ||
      userCounts.lastDailyCount !== dateString
    ) {
      updateData.totalClients = increment(1);
      batch.set(userCountsRef, { lastDailyCount: dateString }, { merge: true });
    }

    // Mise à jour des statistiques quotidiennes
    batch.set(dailyStatsRef, updateData, { merge: true });

    // Mise à jour du comptage mensuel
    if (
      !userCounts.lastMonthlyCount ||
      userCounts.lastMonthlyCount !== yearMonth
    ) {
      batch.set(
        monthlyStatsRef,
        { ...updateData, totalClients: increment(1) },
        { merge: true }
      );
      batch.set(
        userCountsRef,
        { lastMonthlyCount: yearMonth },
        { merge: true }
      );
    } else {
      batch.set(monthlyStatsRef, updateData, { merge: true });
    }

    // Mise à jour du comptage annuel
    if (!userCounts.lastYearlyCount || userCounts.lastYearlyCount !== year) {
      batch.set(
        yearlyStatsRef,
        { ...updateData, totalClients: increment(1) },
        { merge: true }
      );
      batch.set(userCountsRef, { lastYearlyCount: year }, { merge: true });
    } else {
      batch.set(yearlyStatsRef, updateData, { merge: true });
    }

    // Mise à jour du comptage de tous les temps (une seule fois par utilisateur)
    if (!userCounts.countedAllTime) {
      batch.set(
        allTimeStatsRef,
        { ...updateData, totalClients: increment(1) },
        { merge: true }
      );
      batch.set(userCountsRef, { countedAllTime: true }, { merge: true });
    } else {
      batch.set(allTimeStatsRef, updateData, { merge: true });
    }

    await batch.commit();
  };

  if (subscriptionStatus && !subscriptionStatus.isActive) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold mb-4">Abonnement inactif</h1>
        <p className="text-center mb-4">
          Votre abonnement a expiré ou le paiement a échoué.
        </p>
        <Button onClick={() => navigate("/payment")}>
          Renouveler l'abonnement
        </Button>
      </div>
    );
  }

  const checkSubscriptionAndClients = async (merchantId: string) => {
    const currentDate = new Date();
    const yearMonth = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}`;
    const monthlyStatsRef = doc(
      db,
      "analytics",
      merchantId,
      "monthlyStats",
      yearMonth
    );
    const monthlyStatsDoc = await getDoc(monthlyStatsRef);
    const monthlyNewSignUps = monthlyStatsDoc.data()?.newSignUps || 0;

    let subscriptionLevel = "riviere_access";
    if (entitlements) {
      if (entitlements["ocean_access"]?.isActive) {
        subscriptionLevel = "ocean_access";
      } else if (entitlements["lac_access"]?.isActive) {
        subscriptionLevel = "lac_access";
      } else if (entitlements["riviere_access"]?.isActive) {
        subscriptionLevel = "riviere_access";
      }
    }

    return { subscriptionLevel, monthlyNewSignUps };
  };

  const getSubscriptionLimit = (subscriptionLevel: string) => {
    switch (subscriptionLevel) {
      case "ocean_access":
        return 500;
      case "lac_access":
        return 250;
      case "riviere_access":
        return 100;
      default:
        return 100;
    }
  };

  const getSubscriptionDisplayName = (subscriptionLevel: string): string => {
    switch (subscriptionLevel) {
      case "ocean_access":
        return "Océan";
      case "lac_access":
        return "Lac";
      case "riviere_access":
        return "Rivière";
      default:
        return subscriptionLevel;
    }
  };

  const showUpgradeWarning = async (currentLevel: string) => {
    const displayName = getSubscriptionDisplayName(currentLevel);
    const limit = getSubscriptionLimit(currentLevel);
    if (isNative) {
      await CapacitorDialog.alert({
        title: "Limite mensuelle de nouvelles cartes proche",
        message: `Vous avez dépassé la limite mensuelle de ${limit} nouvelles cartes pour votre abonnement ${displayName}. Envisagez une mise à niveau pour continuer à croître.`,
        buttonTitle: "Compris",
      });
    } else {
      alert(
        `Vous avez dépassé la limite mensuelle de ${limit} nouvelles cartes pour votre abonnement ${displayName}. Envisagez une mise à niveau pour continuer à croître.`
      );
    }
  };

  const showUpgradeRequired = async (currentLevel: string) => {
    const displayName = getSubscriptionDisplayName(currentLevel);
    const limit = getSubscriptionLimit(currentLevel);
    if (isNative) {
      await CapacitorDialog.alert({
        title: "Limite mensuelle de nouvelles cartes atteinte",
        message: `Vous avez dépassé la limite mensuelle de ${limit} (+10%) nouvelles cartes pour votre abonnement ${displayName}. Vous ne pouvez plus ajouter de nouvelles cartes ce mois-ci. Veuillez mettre à niveau votre abonnement pour ajouter de nouvelles cartes.`,
        buttonTitle: "Compris",
      });
    } else {
      alert(
        `Vous avez dépassé la limite mensuelle de ${limit} (+10%) nouvelles cartes pour votre abonnement ${displayName}. Vous ne pouvez plus ajouter de nouvelles cartes ce mois-ci. Veuillez mettre à niveau votre abonnement pour ajouter de nouvelles cartes.`
      );
    }
  };

  const handleUpgradeConfirm = () => {
    setShowUpgradeDialog(false);
    navigate("/settings");
  };

  const handleUpgradeCancel = () => {
    setShowUpgradeDialog(false);
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-[10001] p-4">
        <div className="flex justify-between items-center">
          <FideliPassLogo className="h-5 w-auto" />
          <div className="w-12 h-12"></div>
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center justify-start p-4 overflow-hidden">
        {merchantShops.length === 1 ? (
          <Card className="w-full max-w-md mb-4">
            <CardHeader>
              <CardTitle>{merchantShops[0].name}</CardTitle>
            </CardHeader>
          </Card>
        ) : (
          <Card className="w-full max-w-md mb-4">
            <CardHeader>
              <CardTitle>Sélectionnez un commerce</CardTitle>
            </CardHeader>
            <CardContent>
              {/* Ici, vous pouvez ajouter un sélecteur de commerce si nécessaire */}
            </CardContent>
          </Card>
        )}

        <div className="w-full max-w-md aspect-square relative mb-4">
          {isNative ? (
            <div
              id="camera-preview"
              className="fixed inset-0 z-50 flex items-center justify-center"
            >
              {!isScanning && (
                <Button
                  onClick={startScanner}
                  disabled={isScanning}
                  size="lg"
                  className="z-50 w-64 h-12 text-lg"
                >
                  {isScanning ? "Scan en cours..." : "Démarrer le scan"}
                </Button>
              )}
            </div>
          ) : (
            <div className="w-full h-full bg-gray-200 flex flex-col items-center justify-center">
              <video
                ref={videoRef}
                className="w-full h-full object-cover rounded-lg"
                playsInline
                autoPlay
                onLoadedMetadata={handleVideoMetadataLoaded}
              />
              <canvas ref={canvasRef} className="hidden" />
            </div>
          )}
          {isOverlayVisible && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
              {showAddButton && (
                <Button
                  onClick={handleAddPoint}
                  size="lg"
                  className="w-32 h-32 rounded-full z-[10000]"
                >
                  <Plus className="h-16 w-16" />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog open={showUpgradeDialog} onOpenChange={setShowUpgradeDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Limite de clients atteinte</DialogTitle>
            <DialogDescription>
              Vous avez atteint la limite de clients pour votre abonnement{" "}
              {currentUpgradeLevel}. Voulez-vous mettre à niveau maintenant ?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={handleUpgradeCancel} variant="outline">
              Plus tard
            </Button>
            <Button onClick={handleUpgradeConfirm}>Mettre à niveau</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CameraPage;
