import React, { useState } from "react";
import { Button } from "../@/components/ui/button";
import {
  FileText,
  ShieldCheck,
  Scale,
  ChevronLeft,
  ReceiptText,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../@/components/ui/card";
import { Capacitor } from "@capacitor/core";

const AboutPage: React.FC<{ onExitComplete: () => void }> = ({
  onExitComplete,
}) => {
  const navigate = useNavigate();
  const isNative = Capacitor.isNativePlatform();
  const [isExiting, setIsExiting] = useState(false);

  const handleBackClick = () => {
    setIsExiting(true);
    setTimeout(() => {
      onExitComplete();
    }, 300); // 300ms pour la durée de l'animation
  };

  const openLink = (url: string) => {
    if (isNative) {
      // Sur mobile, utilisez le navigateur intégré de Capacitor
      import('@capacitor/browser').then(module => {
        module.Browser.open({ url });
      });
    } else {
      // Sur ordinateur, ouvrez dans un nouvel onglet
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isExiting ? "slide-out-right" : "slide-in-right"
      } ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full w-12 h-12"
            onClick={handleBackClick}
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
          <div className="w-12 h-12"></div>{" "}
          {/* Espace vide pour maintenir l'alignement */}
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
          <Card className="mb-4">
            <CardHeader>
              <CardTitle>À propos</CardTitle>
            </CardHeader>
            <CardContent>
              <Button
                className="w-full mb-4 justify-start text-lg h-12 py-0"
                variant="ghost"
                onClick={() =>
                  openLink("https://fidelipassapp.com/app-privacy-policy")
                }
              >
                <ShieldCheck className="mr-2 h-6 w-6" /> Politique de
                confidentialité
              </Button>

              <Button
                className="w-full mb-4 justify-start text-lg h-12 py-0"
                variant="ghost"
                onClick={() =>
                  openLink("https://fidelipassapp.com/app-terms-of-services")
                }
              >
                <Scale className="mr-2 h-6 w-6" /> Conditions d'utilisation
              </Button>

              <Button
                className="w-full mb-4 justify-start text-lg h-12 py-0"
                variant="ghost"
                onClick={() =>
                  openLink("https://fidelipassapp.com/app-legal-notice")
                }
              >
                <FileText className="mr-2 h-6 w-6" /> Mentions légales
              </Button>

              <Button
                className="w-full mb-4 justify-start text-lg h-12 py-0"
                variant="ghost"
                onClick={() =>
                  openLink("https://fidelipassapp.com/terms-of-sale")
                }
              >
                <ReceiptText className="mr-2 h-6 w-6" /> Conditions de vente
              </Button>
            </CardContent>
          </Card>
          <p className="text-center mt-5 text-sm text-gray-600">
            Développé par Gandal Foglia
          </p>
          <p className="text-center text-sm text-gray-600">
            Propulsé par NeoXper
          </p>
          <p className="text-center mt-5 text-sm text-gray-600">
            Version de l'application : 1.0
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
