import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getApp, deleteApp } from "firebase/app";

// Composant wrapper pour gérer Firebase
const FirebaseManager: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    const unloadCallback = async () => {
      try {
        const app = getApp();
        await deleteApp(app);
      } catch (error) {
        console.error("Erreur lors de la suppression de l'app Firebase:", error);
      }
    };

    window.addEventListener("beforeunload", unloadCallback);

    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  return <>{children}</>;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FirebaseManager>
      <App />
    </FirebaseManager>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();