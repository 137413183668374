import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import MerchantProfilesPage from './MerchantProfilesPage';
import SettingsPage from './SettingsPage';

interface MerchantProfilesPageWrapperProps {
  user: any; // Replace 'any' with the correct type of your user
}

const MerchantProfilesWrapper: React.FC<MerchantProfilesPageWrapperProps> = ({ user }) => {
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleExitComplete = () => {
    navigate('/settings');
  };

  return (
    <div className="relative w-full h-full">
      <div className={`absolute inset-0 ${isExiting ? 'z-10' : 'z-20'}`}>
        <MerchantProfilesPage onExitComplete={handleExitComplete} />
      </div>
      <div className={`absolute inset-0 ${isExiting ? 'z-20' : 'z-10'}`}>
        <SettingsPage />
      </div>
    </div>
  );
};

export default MerchantProfilesWrapper;