import React from 'react';
import ShopCard from './ShopCard';
import { Shop } from '../App';
import { Telescope } from 'lucide-react';
import { Button } from "../@/components/ui/button"
import { Link } from 'react-router-dom';

interface ShopListProps {
  shops: Shop[];
  onShopClick: (shop: Shop) => void;
}

const ShopList: React.FC<ShopListProps> = ({ shops, onShopClick }) => {
  if (shops.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center mt-4">
        <p className="text-center text-gray-500 mb-4">
          Aucune carte de fidélité ajoutée pour le moment. Cliquez sur "Découvrir" pour explorer et ajouter des commerces.
        </p>
        <Link to="/add-shop">
          <Button className="flex items-center gap-2">
            <Telescope className="h-5 w-5" />
            Découvrir
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div>
      {shops.map((shop, index) => (
        <ShopCard
          key={shop.id}
          id={shop.id}
          name={shop.name}
          progression={shop.progression}
          redemptionCount={shop.redemptionCount}
          bubbleCount={shop.bubbleCount}
          index={index}
          onShopClick={() => onShopClick(shop)}
        />
      ))}
    </div>
  );
};

export default ShopList;