import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../@/components/ui/card"

interface ShopCardProps {
  id: string;
  name: string;
  progression: number;
  redemptionCount: number;
  bubbleCount: number;
  index: number;
  onShopClick: () => void;
}

const ShopCard: React.FC<ShopCardProps> = ({ id, name, progression, redemptionCount, bubbleCount, index, onShopClick }) => {
  return (
    <Card className="mb-4 cursor-pointer" onClick={onShopClick}>
      <CardHeader>
        <CardTitle>
          {name}
          {redemptionCount > 0 && (
            <span className="ml-2 text-sm font-normal text-gray-500">
              {redemptionCount} x
            </span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-wrap gap-2 mb-4">
          {[...Array(bubbleCount)].map((_, i) => (
            <div 
              key={i} 
              className={`w-6 h-6 rounded-full ${i < progression ? 'bg-black' : 'bg-gray-300'}`}
            ></div>
          ))}
        </div>
        <div className="text-sm text-gray-500">
          {progression} / {bubbleCount}
        </div>
      </CardContent>
    </Card>
  );
};

export default ShopCard;