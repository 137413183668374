import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import AccountPage from './AccountPage';
import SettingsPage from './SettingsPage';

interface AccountPageWrapperProps {
  user: any; // Remplacez 'any' par le type correct de votre utilisateur
}

const AccountPageWrapper: React.FC<AccountPageWrapperProps> = ({ user }) => {
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleExitComplete = () => {
    navigate('/settings');
  };

  return (
    <div className="relative w-full h-full">
      <div className={`absolute inset-0 ${isExiting ? 'z-10' : 'z-20'}`}>
        <AccountPage onExitComplete={handleExitComplete} />
      </div>
      <div className={`absolute inset-0 ${isExiting ? 'z-20' : 'z-10'}`}>
        <SettingsPage />
      </div>
    </div>
  );
};

export default AccountPageWrapper;