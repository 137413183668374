// Spinner.tsx
import React from 'react';
import './spinner.css'; // Import your CSS file for styling

const Spinner: React.FC = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <p>Chargement...</p>
    </div>
  );
};

export default Spinner;
