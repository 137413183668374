import React, { useState, useEffect, useRef } from "react";
import { Phone, Globe, Instagram, Facebook, Search, Map } from "lucide-react";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import { useNavigate } from "react-router-dom";
import { Shop } from "../App";
import { Card, CardHeader, CardTitle } from "../@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../@/components/ui/dialog";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as FideliPassLogo } from "../assets/FideliPass nom.svg";
import { Browser } from "@capacitor/browser";
import { getCountryCode, countryMap } from "../utils/countryUtils";
import { auth, db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

interface AddShopPageProps {
  onAddShop: (shopId: string) => void;
  availableShops: Shop[];
}

const AddShopPage: React.FC<AddShopPageProps> = ({
  onAddShop,
  availableShops,
}) => {
  const navigate = useNavigate();
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isNative = Capacitor.isNativePlatform();
  const [userCountry, setUserCountry] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [lastReloadTime, setLastReloadTime] = useState<number>(0);
  const [visibleShopsCount, setVisibleShopsCount] = useState(4);
  const SHOPS_PER_PAGE = 4;

  const filteredShops = availableShops.filter(
    (shop) =>
      shop.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      shop.city.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const visibleShops = filteredShops.slice(0, visibleShopsCount);

  const fetchAndUpdateUserCountry = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserCountry(userData.countryId || "");
      }
      setIsLoading(false);
    }
  };

  const handleReload = () => {
    const now = Date.now();
    const cooldownPeriod = 5000; // 5 secondes de cooldown

    if (now - lastReloadTime > cooldownPeriod) {
      setLastReloadTime(now);
      fetchAndUpdateUserCountry();
    } else {
      // Optionnel : informer l'utilisateur qu'il doit attendre
      alert("Veuillez patienter avant de recharger à nouveau.");
    }
  };

  useEffect(() => {
    handleReload();
  }, []);

  useEffect(() => {
    setVisibleShopsCount(SHOPS_PER_PAGE);
  }, [searchTerm]);

  const handleCountryChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCountry = event.target.value;
    const countryCode = getCountryCode(selectedCountry);
    setUserCountry(countryCode);

    const user = auth.currentUser;
    if (user) {
      await updateDoc(doc(db, "users", user.uid), { countryId: countryCode });
    }
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 300);
    }
  };

  const handleBlur = () => {
    if (searchTerm === "") {
      setIsSearchOpen(false);
    }
  };

  const handleShopClick = (shop: Shop) => {
    setSelectedShop(shop);
    setIsDialogOpen(true);
  };

  const loadMoreShops = () => {
    setVisibleShopsCount((prevCount) => prevCount + SHOPS_PER_PAGE);
  };

  const openLink = async (url: string | undefined) => {
    if (!url) return;

    if (Capacitor.isNativePlatform()) {
      // Sur mobile, utilisez le navigateur intégré de Capacitor
      await Browser.open({ url });
    } else {
      // Sur ordinateur, ouvrez dans une nouvelle fenêtre
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const openInMaps = (shop: Shop | null) => {
    if (!shop) return;

    const address = `${shop.address}, ${shop.postalCode} ${shop.city}, ${shop.country}`;
    const encodedAddress = encodeURIComponent(address);

    // Vérifier si l'utilisateur est sur un appareil iOS ou macOS
    const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(
      navigator.userAgent
    );

    if (isAppleDevice) {
      // Ouvrir dans Apple Maps
      window.location.href = `maps:0,0?q=${encodedAddress}`;
    } else {
      // Ouvrir dans Google Maps
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
        "_blank"
      );
    }
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative ? "native-platform" : ""
      }`}
    >
      <div
        className={`bg-white z-10 ${
          isNative
            ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
            : ""
        }`}
      >
        <div className="flex justify-between items-center p-4">
          <div
            className={`transition-opacity duration-300 ${
              isSearchOpen ? "hidden" : "block"
            }`}
          >
            <FideliPassLogo className="h-5 w-auto" />
          </div>
          <div
            className={`relative flex-grow transition-all duration-300 ${
              isSearchOpen ? "w-full" : "w-auto"
            }`}
          >
            <Input
              ref={searchInputRef}
              type="text"
              placeholder="Cherchez un commerce ou une ville"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`pl-2 pr-10 py-1 w-full h-12 transition-all duration-300 ${
                isSearchOpen ? "opacity-100" : "opacity-0 w-0"
              }`}
              onBlur={handleBlur}
            />
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-0 top-1/2 transform -translate-y-1/2 h-12 w-12 rounded-full"
              onClick={toggleSearch}
            >
              <Search className="h-6 w-6" />
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <Button onClick={() => navigate("/map")} className="w-full h-12">
            <Map className="mr-2 h-5 w-5" />
            Découvrir sur le plan
          </Button>
        </div>
      </div>
      <div className={`flex-1 overflow-y-auto ${isNative ? "pb-32" : "pb-24"}`}>
        <div className="p-4">
          {isLoading ? (
            <p>Chargement...</p>
          ) : userCountry === "" ? (
            <div className="text-center">
              <p className="mb-4 text-left text-gray-600">Choisissez un pays</p>
              <select
                value={userCountry}
                onChange={handleCountryChange}
                className="w-full h-12 bg-white text-gray-600 border border-gray-300 rounded-md px-3 py-2 mb-4"
              >
                <option value="">Sélectionnez un pays</option>
                {Object.keys(countryMap).map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
          ) : filteredShops.length === 0 ? (
            <div className="text-center">
              <p className="mb-4 text-left text-gray-600">
                Aucun nouveau commerce n'est disponible à ajouter.
              </p>
              <p className="mb-4 text-left text-gray-600">
                Vous ne voyez pas votre commerce préféré ? Faites-le nous savoir
                à l'aide du bouton ci-dessous.
              </p>
              <Button
                onClick={() =>
                  (window.location.href = "mailto:team@fidelipassapp.com")
                }
                className="w-full h-12 bg-white text-gray-600 border border-gray-600 hover:bg-gray-200"
              >
                Recommander un commerce
              </Button>
            </div>
          ) : (
            <>
            {visibleShops.map((shop) => (
              <Card
                key={shop.id}
                className="mb-4 cursor-pointer"
                onClick={() => handleShopClick(shop)}
              >
                <CardHeader>
                  <CardTitle>{shop.name}</CardTitle>
                  <p className="text-sm text-gray-500">{shop.city}</p>
                </CardHeader>
              </Card>
             ))}
             {visibleShopsCount < filteredShops.length && (
               <Button
                 onClick={loadMoreShops}
                 className="w-full h-12 mt-4"
               >
                 Afficher plus de commerces
               </Button>
             )}
           </>
          )}
        </div>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px] rounded-lg">
          <DialogHeader>
            <DialogTitle className="text-left">
              {selectedShop?.name || "Détails du magasin"}
            </DialogTitle>
            <DialogDescription className="text-left text-sm text-muted-foreground">
              {selectedShop?.address || "Adresse non spécifiée"},{" "}
              {selectedShop?.postalCode || ""} {selectedShop?.city || ""}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4 text-left">
            {selectedShop?.showDescription && selectedShop?.description && (
              <p className="text-left">{selectedShop.description}</p>
            )}
            {selectedShop?.offre && (
              <p className="mt-2 font-semibold text-left">
                Offre à la complétion : {selectedShop.offre}
              </p>
            )}
          </div>
          <div className="flex justify-start space-x-4 mt-4">
            <button
              onClick={() => openInMaps(selectedShop)}
              className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
            >
              <Map className="w-6 h-6" />
            </button>
            {selectedShop?.showPhone && selectedShop?.phone && (
              <a
                href={`tel:${selectedShop.phone}`}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Phone className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showWebsite && selectedShop?.website && (
              <button
                onClick={() => openLink(selectedShop.website)}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Globe className="w-6 h-6" />
              </button>
            )}
            {selectedShop?.showInstagram && selectedShop?.instagram && (
              <a
                href={selectedShop.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Instagram className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showFacebook && selectedShop?.facebook && (
              <a
                href={selectedShop.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Facebook className="w-6 h-6" />
              </a>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddShopPage;
