import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { Button } from "../@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../@/components/ui/card";
import { AlertTriangle, ChevronLeft } from "lucide-react";
import { Capacitor } from "@capacitor/core";
import useRevenueCatEntitlement from "../hooks/useRevenueCatEntitlement";
import { Browser } from "@capacitor/browser";
import { Dialog } from "@capacitor/dialog";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { Purchases as PurchasesWeb } from "@revenuecat/purchases-js";
import { Progress } from "../@/components/ui/progress";

interface SubscriptionData {
  status: string;
  currentPeriodEnd: string;
  entitlementName?: string;
}

const ManageSubscriptionPage: React.FC<{ onExitComplete: () => void }> = ({
  onExitComplete,
}) => {
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionData | null>(null);
  const isNative = Capacitor.isNativePlatform();
  const {
    hasActiveEntitlement,
    entitlements,
    subscriptionStatus,
    isLoading: isRevenueCatLoading,
  } = useRevenueCatEntitlement();
  const [totalClients, setTotalClients] = useState<number | null>(null);
  const [isExiting, setIsExiting] = useState(false);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleUpdatePaymentMethod = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        if (!hasActiveEntitlement) {
          navigate("/payment");
        } else {
          let managementURL;
          if (isNative) {
            const { customerInfo } = await Purchases.getCustomerInfo();
            managementURL = customerInfo.managementURL;
          } else {
            const customerInfo =
              await PurchasesWeb.getSharedInstance().getCustomerInfo();
            managementURL = customerInfo.managementURL;
          }

          //For test purposes
          /*const stripeLoginUrl =
            "https://billing.stripe.com/p/login/test_bIYbJpcxefme6CAfYY";*/
          const stripeLoginUrl =
            "https://billing.stripe.com/p/login/7sI037ezwb763o47ss";

          if (managementURL) {
            // L'abonnement a été effectué via RevenueCat
            if (isNative) {
              await Browser.open({ url: managementURL });
            } else {
              window.open(managementURL, "_blank");
            }
          } else {
            // L'abonnement a été effectué via Stripe
            if (isNative) {
              renderDialog("externalSubscriptionWarning");
            } else {
              window.open(stripeLoginUrl, "_blank");
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de la gestion de l'abonnement:", error);
        if (isNative) {
          await Dialog.alert({
            title: "Erreur",
            message:
              "Une erreur est survenue lors de la tentative de gestion de l'abonnement.",
            buttonTitle: "OK",
          });
        } else {
          alert(
            "Une erreur est survenue lors de la tentative de gestion de l'abonnement."
          );
        }
      }
    }
  };

  /**
   * Fetches the total number of clients for a given user.
   *
   * @param userId - The ID of the user.
   * @returns The total number of new sign-ups for the current month, or 0 if there is an error or no data available.
   */
  const fetchTotalClients = async (userId: string) => {
    try {
      const merchantDoc = await getDoc(doc(db, "merchants", userId));
      if (merchantDoc.exists()) {
        const shopId = merchantDoc.data().shopId;
        const currentDate = new Date();
        const yearMonth = `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}`;
        const analyticsDoc = await getDoc(
          doc(db, "analytics", shopId, "monthlyStats", yearMonth)
        );
        if (analyticsDoc.exists()) {
          return analyticsDoc.data().newSignUps || 0;
        }
      }
      return 0;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du nombre de nouvelles cartes ce mois-ci:",
        error
      );
      return 0;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const totalClientsCount = await fetchTotalClients(user.uid);
        setTotalClients(totalClientsCount);
      }
    };

    fetchData();
  }, []);

  const renderDialog = async (type: string) => {
    if (isNative) {
      switch (type) {
        case "externalSubscriptionWarning":
          await Dialog.alert({
            title: "Gérer l'abonnement",
            message:
              "Cet abonnement ne peut pas être annulé ou modifié dans cette application car il a été acheté sur une autre plateforme. Pour gérer votre abonnement, veuillez vous connecter à votre compte où vous avez effectué l'achat.",
            buttonTitle: "Compris",
          });
          break;
      }
    }
  };

  const getSubscriptionInfo = () => {
    if (hasActiveEntitlement && entitlements) {
      const activeEntitlement = Object.values(entitlements)[0];
      return {
        currentPeriodEnd: activeEntitlement.expirationDate || "N/A",
        entitlementName: activeEntitlement.identifier,
      };
    }
    return subscriptionData;
  };

  const subscriptionInfo = getSubscriptionInfo();

  if (isRevenueCatLoading) {
    return <p>Chargement des données de l'abonnement...</p>;
  }

  const getEntitlementDisplayName = (entitlementName: string) => {
    switch (entitlementName) {
      case "riviere_access":
        return "Rivière";
      case "lac_access":
        return "Lac";
      case "ocean_access":
        return "Océan";
      default:
        return entitlementName;
    }
  };

  const getMaxClients = (entitlementName: string | undefined) => {
    switch (entitlementName) {
      case "ocean_access":
        return "500";
      case "lac_access":
        return "250";
      case "riviere_access":
        return "100";
      default:
        return "100";
    }
  };

  const handleChangeSubscription = async () => {
    if (isNative) {
      try {
        const { customerInfo } = await Purchases.getCustomerInfo();
        if (customerInfo.managementURL) {
          // L'abonnement a été fait sur un appareil natif, on peut changer l'abonnement
          navigate("/change-subscription");
        } else {
          // L'abonnement a été fait sur Stripe, on ne peut pas le gérer sur l'appareil natif
          await Dialog.alert({
            title: "Gestion d'abonnement impossible",
            message:
              "Cet abonnement ne peut pas être modifié dans cette application car il a été acheté sur une autre plateforme. Pour gérer votre abonnement, veuillez vous connecter à votre compte où vous avez effectué l'achat.",
            buttonTitle: "Compris",
          });
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations client:",
          error
        );
        await Dialog.alert({
          title: "Erreur",
          message:
            "Une erreur est survenue lors de la vérification de votre abonnement. Veuillez réessayer plus tard.",
          buttonTitle: "OK",
        });
      }
    } else {
      // Pour le web
      const stripeLoginUrl =
        "https://billing.stripe.com/p/login/7sI037ezwb763o47ss";
      window.open(stripeLoginUrl, "_blank");
    }
  };

  const isOverLimit = (
    totalClients: number | null,
    maxClients: number | string
  ): boolean => {
    if (totalClients === null) return false;
    const numericMaxClients =
      typeof maxClients === "string" ? parseInt(maxClients, 10) : maxClients;
    if (isNaN(numericMaxClients)) return false;
    return totalClients > numericMaxClients;
  };

  const isApproachingLimit = (
    totalClients: number | null,
    maxClients: number | string
  ): boolean => {
    if (totalClients === null) return false;
    const numericMaxClients =
      typeof maxClients === "string" ? parseInt(maxClients, 10) : maxClients;
    if (isNaN(numericMaxClients)) return false;
    return totalClients >= numericMaxClients * 0.9;
  };

  const handleBackClick = () => {
    setIsExiting(true);
    setTimeout(() => {
      onExitComplete();
    }, 300); // 300ms pour la durée de l'animation
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isExiting ? "slide-out-right" : "slide-in-right"
      } ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full w-12 h-12"
            onClick={handleBackClick}
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
          <div className="w-12 h-12"></div>{" "}
          {/* Espace vide pour maintenir l'alignement */}
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
          <Card>
            <CardHeader>
              <CardTitle>Détails de l'abonnement</CardTitle>
            </CardHeader>
            <CardContent>
              {subscriptionInfo ? (
                <>
                  <Card className="mb-4">
                    <CardContent className="p-4">
                      {subscriptionInfo.entitlementName && (
                        <p className="font-semibold mb-2">
                          Abonnement{" "}
                          {getEntitlementDisplayName(
                            subscriptionInfo.entitlementName
                          )}
                        </p>
                      )}
                      {subscriptionStatus && subscriptionStatus.isActive ? ( //abo actif
                        <p>
                          Fin de la période actuelle :{" "}
                          {formatDate(subscriptionInfo.currentPeriodEnd)}
                        </p>
                      ) : (
                        <p>
                          Fin de l'abonnement :{" "}
                          {formatDate(subscriptionInfo.currentPeriodEnd)}
                        </p>
                      )}
                    </CardContent>
                    <CardFooter>
                      <Button
                        onClick={handleChangeSubscription}
                        className={`w-full h-12 ${
                          isOverLimit(
                            totalClients,
                            getMaxClients(
                              subscriptionInfo?.entitlementName || ""
                            )
                          )
                            ? "bg-red-500 hover:bg-red-600 text-white"
                            : ""
                        }`}
                        disabled={
                          !subscriptionStatus || !subscriptionStatus.isActive
                        }
                      >
                        {isNative
                          ? "Changer de formule"
                          : "Gérer mon abonnement"}
                      </Button>
                    </CardFooter>
                  </Card>
                  {totalClients !== null &&
                    subscriptionInfo.entitlementName && (
                      <Card
                        className={`bg-gray-100 
                        }`}
                      >
                        <CardContent className="p-4">
                          <p className="font-semibold mb-2">
                            Nouvelles cartes ce mois-ci
                          </p>
                          {isOverLimit(
                            totalClients,
                            getMaxClients(
                              subscriptionInfo?.entitlementName || ""
                            )
                          ) ? (
                            <div className="flex items-center mb-2 text-red-500">
                              <AlertTriangle className="w-4 h-4 mr-1 flex-shrink-0" />
                              <span className="text-xs">
                                Vous avez atteint la limite mensuelle de
                                nouvelles cartes. Veuillez mettre à niveau votre
                                abonnement pour pouvoir ajouter plus de cartes
                                ce mois-ci.
                              </span>
                            </div>
                          ) : (
                            isApproachingLimit(
                              totalClients,
                              getMaxClients(
                                subscriptionInfo?.entitlementName || ""
                              )
                            ) && (
                              <div className="flex items-center mb-2 text-black">
                                <AlertTriangle className="w-4 h-4 mr-1 flex-shrink-0" />
                                <span className="text-xs">
                                  Vous approchez de la limite mensuelle de
                                  nouvelles cartes. Une mise à niveau pourrait
                                  être nécessaire pour continuer à croître.
                                </span>
                              </div>
                            )
                          )}
                          <div className="flex items-center mb-2">
                            <p>
                              {totalClients ?? 0} /{" "}
                              {getMaxClients(
                                subscriptionInfo?.entitlementName || ""
                              )}
                            </p>
                          </div>
                          <Progress
                            value={
                              ((totalClients ?? 0) /
                                parseInt(
                                  getMaxClients(
                                    subscriptionInfo?.entitlementName || ""
                                  ),
                                  10
                                )) *
                              100
                            }
                            className="h-2 bg-gray-200"
                            indicatorClassName={
                              isOverLimit(
                                totalClients,
                                getMaxClients(
                                  subscriptionInfo?.entitlementName || ""
                                )
                              )
                                ? "bg-red-500"
                                : "bg-black"
                            }
                          />
                        </CardContent>
                      </Card>
                    )}
                </>
              ) : (
                <p>Aucune information d'abonnement disponible.</p>
              )}
            </CardContent>
            <CardFooter>
              <Button
                onClick={handleUpdatePaymentMethod}
                className="mt-4 w-full h-12"
              >
                {subscriptionStatus &&
                !subscriptionStatus.isActive && // abo pas actif
                !hasActiveEntitlement
                  ? "Renouveler mon abonnement"
                  : "Gérer mon abonnement"}
              </Button>
            </CardFooter>
          </Card>
          <p className="text-center mt-5 text-sm text-gray-600">
            Merci pour votre fidélité !
          </p>
        </div>
      </div>
    </div>
  );
};

export default ManageSubscriptionPage;
