import { useState, useEffect } from "react";
import {
  Purchases,
  PurchasesEntitlementInfo as PurchasesEntitlementInfoNative,
} from "@revenuecat/purchases-capacitor";
import {
  Purchases as PurchasesWeb,
  EntitlementInfo as EntitlementInfoWeb,
} from "@revenuecat/purchases-js";
import { Capacitor } from "@capacitor/core";

type EntitlementInfoUnion =
  | { [key: string]: PurchasesEntitlementInfoNative }
  | { [entitlementId: string]: EntitlementInfoWeb };

type SubscriptionStatus = {
  isActive: boolean;
  willRenew: boolean;
  expirationDate: Date | null;
};

interface EntitlementInfo {
  hasActiveEntitlement: boolean;
  entitlements: EntitlementInfoUnion | null;
  subscriptionStatus: SubscriptionStatus | null;
}

const useRevenueCatEntitlement = (): EntitlementInfo & {
  isLoading: boolean;
} => {
  const [entitlementInfo, setEntitlementInfo] = useState<EntitlementInfo>({
    hasActiveEntitlement: false,
    entitlements: null,
    subscriptionStatus: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkEntitlements = async () => {
      try {
        setIsLoading(true);
        let activeEntitlements: EntitlementInfoUnion;
        let subscriptionStatus: SubscriptionStatus | null = null;

        if (Capacitor.isNativePlatform()) {
          // Attendre que Purchases soit configuré
          await new Promise<void>(async (resolve) => {
            const checkConfiguration = async () => {
              const { isConfigured } = await Purchases.isConfigured();
              if (isConfigured) {
                resolve();
              } else {
                setTimeout(checkConfiguration, 100);
              }
            };
            await checkConfiguration();
          });

          const result = await Purchases.getCustomerInfo();
          activeEntitlements = result.customerInfo.entitlements.active;

          // Extraire les informations sur l'abonnement
          const firstActiveEntitlement = Object.values(activeEntitlements)[0] as PurchasesEntitlementInfoNative;
          if (firstActiveEntitlement) {
            subscriptionStatus = {
              isActive: firstActiveEntitlement.isActive,
              willRenew: firstActiveEntitlement.willRenew,
              expirationDate: firstActiveEntitlement.expirationDate ? new Date(firstActiveEntitlement.expirationDate) : null,
            };
          }
        } else {
          // Attendre que PurchasesWeb soit configuré
          await new Promise<void>((resolve) => {
            const checkConfiguration = () => {
              try {
                PurchasesWeb.getSharedInstance();
                resolve();
              } catch {
                setTimeout(checkConfiguration, 100);
              }
            };
            checkConfiguration();
          });

          const customerInfo =
            await PurchasesWeb.getSharedInstance().getCustomerInfo();
          activeEntitlements = customerInfo.entitlements.active;

          // Extraire les informations sur l'abonnement
          const firstActiveEntitlement = Object.values(activeEntitlements)[0] as EntitlementInfoWeb;
          if (firstActiveEntitlement) {
            subscriptionStatus = {
              isActive: firstActiveEntitlement.isActive,
              willRenew: firstActiveEntitlement.willRenew,
              expirationDate: firstActiveEntitlement.expirationDate ? new Date(firstActiveEntitlement.expirationDate) : null,
            };
          }
        }

        const hasActiveEntitlement = Object.keys(activeEntitlements).length > 0;

        setEntitlementInfo({
          hasActiveEntitlement,
          entitlements: activeEntitlements,
          subscriptionStatus,
        });
      } catch (error) {
        console.error(
          "Erreur lors de la vérification des droits RevenueCat:",
          error
        );
        setEntitlementInfo({
          hasActiveEntitlement: false,
          entitlements: null,
          subscriptionStatus: null,
        });
      } finally {
        setIsLoading(false);
      }
    };

    checkEntitlements();
  }, []);

  return { ...entitlementInfo, isLoading };
};

export default useRevenueCatEntitlement;