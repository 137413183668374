import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

interface SegmentedPickerProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

const SegmentedPicker: React.FC<SegmentedPickerProps> = ({ options, value, onChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const index = options.indexOf(value);
    if (index !== -1) {
      setActiveIndex(index);
    }
  }, [value, options]);

  const handleSwipe = (direction: "LEFT" | "RIGHT") => {
    let newIndex = activeIndex;
    if (direction === "LEFT" && activeIndex < options.length - 1) {
      newIndex = activeIndex + 1;
    } else if (direction === "RIGHT" && activeIndex > 0) {
      newIndex = activeIndex - 1;
    }
    
    if (newIndex !== activeIndex) {
      onChange(options[newIndex]);
      setActiveIndex(newIndex);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
    trackMouse: true
  });

  return (
    <div
      {...swipeHandlers}
      ref={containerRef}
      className="relative flex rounded-full shadow-sm bg-gray-200 p-1 touch-pan-y"
      style={{ height: "48px" }}
    >
      {options.map((option, index) => (
        <button
          key={option}
          className={`flex-1 z-10 text-sm font-medium transition-colors duration-200 ${
            value === option ? "text-white" : "text-gray-700"
          } focus:outline-none focus:ring-0 focus:z-20 rounded-full`}
          onClick={() => {
            onChange(option);
            setActiveIndex(index);
          }}
        >
          {option}
        </button>
      ))}
      <div
        className="absolute top-1 bottom-1 bg-primary transition-all duration-200 ease-in-out rounded-full"
        style={{
          width: `${100 / options.length}%`,
          left: `${(activeIndex * 100) / options.length + 0.5}%`,
        }}
      />
    </div>
  );
};

export default SegmentedPicker;