import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { Button } from "../@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { Plus, ChevronLeft } from "lucide-react";
import { Capacitor } from "@capacitor/core";
import useRevenueCatEntitlement from "../hooks/useRevenueCatEntitlement";


interface MerchantProfile {
  id: string;
  name: string;
  category: string;
  address: string;
  city: string;
  shopId: string;
  bubbleCount: number;
  postalCode: string;
  country: string;
  canton?: string;
  description?: string;
  website?: string;
  instagram?: string;
  facebook?: string;
  phone?: string;
  showDescription?: boolean;
  showPhone?: boolean;
  showWebsite?: boolean;
  showInstagram?: boolean;
  showFacebook?: boolean;
  userId: string;
}

interface MerchantProfilesPageProps {
  onExitComplete: () => void;
}

const MerchantProfilesPage: React.FC<MerchantProfilesPageProps> = ({ onExitComplete }) => {
  const navigate = useNavigate();
  const [merchantProfiles, setMerchantProfiles] = useState<MerchantProfile[]>(
    []
  );
  const isNative = Capacitor.isNativePlatform();
  useState(false);
  const { hasActiveEntitlement, entitlements } = useRevenueCatEntitlement();
  const [isExiting, setIsExiting] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const location = useLocation();


  useEffect(() => {
    if (location.state?.noAnimation) {
      setShouldAnimate(false);
    } else {
      setShouldAnimate(true);
    }
  }, [location]);

  useEffect(() => {
    const fetchMerchantProfiles = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const q = query(
            collection(db, "merchants"),
            where("userId", "==", user.uid)
          );
          const querySnapshot = await getDocs(q);
          const profiles: MerchantProfile[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.name) {
              profiles.push({
                id: doc.id,
                userId: user.uid, // Ajoutez cette ligne
                ...data,
              } as MerchantProfile);
            }
          });
          setMerchantProfiles(profiles);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des profils commerçants:",
            error
          );
        }
      } else {
        navigate("/login");
      }
    };

    fetchMerchantProfiles();
  }, [navigate]);

  const handleBackClick = () => {
    setIsExiting(true);
    setTimeout(() => {
      onExitComplete();
    }, 300); // durée de l'animation
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      } ${isExiting ? 'slide-out-right' : shouldAnimate ? 'slide-in-right' : ''}`}
    >
      <div className="bg-white z-10 p-4">
      <div className="flex justify-between items-center">
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full w-12 h-12"
          onClick={handleBackClick}
        >
          <ChevronLeft className="h-6 w-6" />
        </Button>
        <div className="w-12 h-12"></div> {/* Espace vide pour maintenir l'alignement */}
      </div>
    </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
          <Card>
            <CardHeader className="flex justify-between items-center px-6">
              <div className="flex items-center w-full">
                <CardTitle className="text-2xl font-semibold leading-none tracking-tight">
                  {merchantProfiles.length > 1
                    ? "Mes commerces"
                    : "Mon commerce"}
                </CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              {merchantProfiles.length === 0 ? (
                <p>Vous n'avez pas encore enregistré de commerce.</p>
              ) : (
                merchantProfiles.map((profile) => (
                  <div key={profile.id} className="mb-4 p-4 border rounded-lg">
                    <div>
                      <h3 className="text-lg font-semibold">{profile.name}</h3>
                      <p>{profile.category}</p>
                      <p>
                        {profile.address}, {profile.city}
                      </p>
                    </div>
                    <div>
                      <div className="mt-4">
                        {hasActiveEntitlement && (
                          <Button
                            className="w-full h-12 bg-black text-white"
                            onClick={() => {
                              navigate(`/edit-merchant/${profile.userId}`);
                            }}
                          >
                            Modifier
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
              {merchantProfiles.length === 0 && (
                <Button
                  onClick={() =>
                    navigate(hasActiveEntitlement ? "/new-merchant" : "/payment")
                  }
                  className="w-full flex items-center justify-center mt-4 h-12"
                >
                  <Plus className="mr-2 h-6 w-6" />
                  Nouveau commerce
                </Button>
              )}
              {merchantProfiles.length > 0 && (
                <p className="mt-4 text-left text-sm text-gray-600 ml-1 mr-1">
                  Si votre commerce n'apparaît pas sur la carte, veuillez vous
                  assurer que l'adresse que vous avez enregistrée est valide
                  dans le menu de modification de votre commerce. Si une erreur
                  persiste, contactez-nous. Sinon, vous pouvez ignorer ce
                  message.
                </p>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MerchantProfilesPage;
