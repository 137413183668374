import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";
import { initializeAppCheck, ReCaptchaEnterpriseProvider, CustomProvider } from 'firebase/app-check';
import { Capacitor } from '@capacitor/core';
import { FirebaseAppCheck } from '@capacitor-firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyCJDtn2Mh9SqH9f7B9ZcfCxn-2hzjVC47w",
    authDomain: "carty-d64ce.firebaseapp.com",
    projectId: "carty-d64ce",
    storageBucket: "carty-d64ce.appspot.com",
    messagingSenderId: "893135045307",
    appId: "1:893135045307:web:f20c9e2143a14d2fcb5f9e",
    measurementId: "G-GBWRT71MJV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west6');
const remoteConfig = getRemoteConfig(app);

let appCheck;

if (Capacitor.isNativePlatform()) {
  const customProvider = {
    getToken: async () => {
      const result = await FirebaseAppCheck.getToken();
      return {
        token: result.token,
        expireTimeMillis: Date.now() + 3600000 // expiration dans 1 heure
      };
    }
  };

  appCheck = initializeAppCheck(app, {
    provider: new CustomProvider(customProvider),
    isTokenAutoRefreshEnabled: true
  });
} else {
  // Configuration web
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LfeaB8qAAAAABjSaNLbYYuDGKWRvRRHETAAkYYE'),
    isTokenAutoRefreshEnabled: true
  });
}

if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN;
}

export { app, auth, db, functions, remoteConfig, appCheck };