import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../@/components/ui/dialog";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import { Capacitor } from "@capacitor/core";
import { z } from "zod";

interface PasswordDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (password: string) => void;
}

const passwordSchema = z
  .string()
  .min(8, "Le mot de passe doit contenir au moins 8 caractères")
  .regex(
    /(?=.*[a-z])/,
    "Le mot de passe doit contenir au moins une lettre minuscule"
  )
  .regex(
    /(?=.*[A-Z])/,
    "Le mot de passe doit contenir au moins une lettre majuscule"
  )
  .regex(/(?=.*\d)/, "Le mot de passe doit contenir au moins un chiffre")
  .regex(
    /(?=.*[@$!%*?&_-])/,
    "Le mot de passe doit contenir au moins un caractère spécial parmi : @, $, !, %, *, ?, &, _, -"
  );

const PasswordDialog: React.FC<PasswordDialogProps> = ({ isOpen, onClose, onConfirm }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isValid, setIsValid] = useState(false);
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    const result = passwordSchema.safeParse(password);
    setIsValid(result.success);
    setError(result.success ? null : result.error.errors[0].message);
  }, [password]);

  const handleConfirm = () => {
    if (isValid) {
      onConfirm(password);
      setPassword('');
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => {}}>
      <DialogContent className={isNative ? "p-4" : ""}>
      <div className="absolute right-4 top-4 w-12 h-12 bg-white z-10"></div>
      <DialogHeader>
          <DialogTitle className={isNative ? "text-xl mb-4" : ""}>Définir un mot de passe</DialogTitle>
        </DialogHeader>
        <Input
          type="password"
          placeholder="Nouveau mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={`mt-4 ${isNative ? "h-12" : ""}`}
        />
        {error && (
          <p className="text-red-500 text-sm mt-2">{error}</p>
        )}
        <DialogFooter className={`mt-6 ${isNative ? "flex flex-col space-y-2" : "flex-row space-x-2"}`}>
          <Button 
            className={isNative ? "w-full h-12" : ""} 
            onClick={onClose}
            variant="outline"
          >
            Annuler
          </Button>
          <Button 
            className={isNative ? "w-full h-12" : ""} 
            onClick={handleConfirm}
            disabled={!isValid}
          >
            Confirmer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordDialog;